/* eslint-disable react/jsx-props-no-spreading */

import React, { lazy, Suspense } from 'react';
import { ConfigProvider } from 'antd'; // eslint-disable-line no-restricted-imports
import Loading from 'helpers/loading/loading';
import antdLocale from 'locales/antdLocale';

const Component = lazy(() => import('components/company-registration-form'));

const CompanyRegistrationForm = (props) => (
  <ConfigProvider locale={antdLocale()}>
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  </ConfigProvider>
);

export default CompanyRegistrationForm;
