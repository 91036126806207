import React, { forwardRef } from 'react';
import { element } from 'prop-types';

const ToggleElement = forwardRef(({ children }, ref) => (
  <div className="co-dropdown__toggle" ref={ref}>
    {children}
  </div>
));

ToggleElement.propTypes = {
  children: element.isRequired,
};

export default ToggleElement;
