export default function initializeSelectedKeys(items) {
  const checkedKeys = [];

  function findActive(values) {
    values.forEach(({ active, children, key }) => {
      if (active) {
        checkedKeys.push(key);
      }

      if (Array.isArray(children)) {
        findActive(children);
      }
    });
  }

  findActive(items);

  return checkedKeys;
}
