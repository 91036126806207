import React, { useEffect, useState } from 'react';

import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';

import { Tree } from 'antd';

const ProductionCycles = ({
  checkedKeys,
  setCheckedKeys,
  treeData,
  expandedKeys,
  drawerHeight,
}) => {
  const onCheck = (checkedKeysValue) => {
    const filteredCheckedKeysValue = checkedKeysValue.filter((item) => !item.toString().split('-', 1).includes('season'));

    setCheckedKeys(filteredCheckedKeysValue);
  };

  const renderTitle = (node) => {
    if (Object.prototype.hasOwnProperty.call(node, 'year')) {
      return <b>{node.title}</b>;
    }

    return node.title;
  };

  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const fieldGroupsElement = document.querySelector('.top-menu-field-group-filter__field-groups .ant-collapse-header');
    const fieldGroupsHeight = fieldGroupsElement?.clientHeight;

    setParentHeight(drawerHeight - 65 - fieldGroupsHeight);
  }, [drawerHeight]);

  return (
    <div
      id="production-cycles-top"
      style={{ minHeight: parentHeight }}
    >
      <Tree
        checkable
        multiple
        switcherIcon={' '}
        height={parentHeight}
        virtual={false}
        defaultExpandAll
        selectable={false}
        onSelect={onCheck}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        treeData={treeData}
        expandedKeys={expandedKeys}
        titleRender={renderTitle}
      />
    </div>
  );
};

ProductionCycles.propTypes = {
  checkedKeys: arrayOf(oneOfType([string, number])),
  setCheckedKeys: func.isRequired,
  expandedKeys: arrayOf(oneOfType([string, number])),
  treeData: arrayOf(shape({
    title: string,
    key: oneOfType([string, number]),
    children: arrayOf(shape({
      title: string,
      key: oneOfType([string, number]),
    })),
  })),
  drawerHeight: number.isRequired,
};

ProductionCycles.defaultProps = {
  checkedKeys: [],
  treeData: [],
  expandedKeys: [],
};

export default ProductionCycles;
