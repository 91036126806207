/* global window */
import React, { useMemo, useState } from 'react';
import I18n from 'i18n-js';
import {
  arrayOf, bool, number, string, shape,
} from 'prop-types';
import { sortBy } from 'lodash-es';
import { Button } from 'antd';
import FaIcon from 'components/fa-icon';
import CoDropdown from 'components/co-dropdown';
import TopMenuFilter from 'components/top-menu-filter';
import flattenTopMenuItemsToMap from 'helpers/flattenTopMenuItemsToMap';
import prepareItems from './helpers/prepareItems';
import initializeSelectedKeys from './helpers/initializeSelectedKeys';

const TopMenuMachineRegions = ({ currentMachineRegion, items }) => {
  const formattedItems = useMemo(() => prepareItems(items), [items]);
  const plainItemsIndex = useMemo(() => (
    flattenTopMenuItemsToMap(formattedItems)
  ), [formattedItems]);
  const [visible, setVisible] = useState(false);

  const toggleVisibleDropdown = () => setVisible((prevState) => !prevState);

  const getToggleButtonClasses = () => {
    const classes = ['top-menu-machine-regions__toggle-btn'];
    if (visible) classes.push('top-menu-machine-regions__toggle-btn_opened');

    return classes.join(' ');
  };

  const selectedKeys = sortBy(initializeSelectedKeys(formattedItems));

  const onSelect = (_, { node }) => {
    window.location = node.path;
  };

  const content = (
    <TopMenuFilter
      items={formattedItems}
      onSelect={onSelect}
      plainItemsIndex={plainItemsIndex}
      selectedKeys={selectedKeys}
      title={I18n.t('activerecord.models.machine_region')}
      visible={visible}
    />
  );

  return (
    <div className="top-menu-machine-regions">
      <CoDropdown
        content={content}
        visible={visible}
        setVisible={setVisible}
        overlayClassName="top-menu-machine-regions__dropdown"
      >
        <Button
          block
          className={getToggleButtonClasses()}
          onClick={toggleVisibleDropdown}
          type="link"
        >
          <FaIcon
            className="top-menu-machine-regions__car-icon"
            icon="car"
            modifier="fa"
          />
          { currentMachineRegion }
          <FaIcon
            className="top-menu-machine-regions__caret-icon"
            icon={(visible ? 'caret-up' : 'caret-down')}
            modifier="fas"
          />
        </Button>
      </CoDropdown>
    </div>
  );
};

TopMenuMachineRegions.propTypes = {
  currentMachineRegion: string,
  items: arrayOf(shape({
    active: bool,
    depth: number,
    hasSubfolders: bool,
    id: number,
    name: string,
    parent: number,
  })),
};

TopMenuMachineRegions.defaultProps = {
  currentMachineRegion: ' - ',
  items: [],
};

export default TopMenuMachineRegions;
