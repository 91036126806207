import React, {
  useState, useRef, useEffect, useLayoutEffect,
} from 'react';
import {
  bool, element, func, string,
} from 'prop-types';
import ToggleElement from './nested/ToggleElement';

const CoDropdown = ({
  content, children, overlayClassName, visible, setVisible,
}) => {
  const wrapperRef = useRef(null);
  const toggleElementRef = useRef(null);
  const [dropdownContentStyle, setDropdownContentStyle] = useState({
    top: '39px',
  });

  const additionalClasses = [
    `co-dropdown__content_${visible ? 'visible' : 'hidden'}`,
    overlayClassName,
  ].join(' ');

  const handleClickOuterDropDown = (e) => {
    const domNodeDropdownWrapper = wrapperRef.current;
    if (domNodeDropdownWrapper && domNodeDropdownWrapper.contains(e.target)) return;
    if (visible) setVisible(false);
  };

  useEffect(() => {
    if (!wrapperRef.current) return undefined;

    const body = wrapperRef.current.closest('body');
    body.addEventListener('click', handleClickOuterDropDown);

    return function cleanup() {
      body.removeEventListener('click', handleClickOuterDropDown);
    };
  });

  useLayoutEffect(() => {
    if (!toggleElementRef.current) return;
    if (!toggleElementRef.current.offsetHeight) return;

    setDropdownContentStyle({
      ...dropdownContentStyle,
      top: toggleElementRef.current.offsetHeight,
    });
  }, [toggleElementRef.current]);

  return (
    <div
      className="co-dropdown"
      ref={wrapperRef}
    >
      <ToggleElement ref={toggleElementRef}>{children}</ToggleElement>
      <div
        className={`co-dropdown__content ${additionalClasses}`}
        style={dropdownContentStyle}
      >
        {content}
      </div>
    </div>
  );
};

CoDropdown.propTypes = {
  children: element.isRequired,
  content: element.isRequired,
  overlayClassName: string,
  setVisible: func.isRequired,
  visible: bool,
};

CoDropdown.defaultProps = {
  overlayClassName: '',
  visible: false,
};

export default CoDropdown;
