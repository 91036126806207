import { set_current_machine_region_path } from 'js-routes/generated/routes';
import recursivlyModifyTree from 'helpers/array/recursivlyModifyTree';
import { cloneDeep } from 'lodash-es';

/**
 * Prepares list for filtering
 *
 * @param {Array} items
 * @returns {Object}
 */
const prepareItems = (items = []) => {
  const clonedItems = cloneDeep(items);

  const updateItem = (item) => {
    // eslint-disable-next-line no-param-reassign
    item.path = set_current_machine_region_path(item.id);
  };

  recursivlyModifyTree({
    tree: clonedItems,
    callback: updateItem,
  });

  const parentItems = clonedItems.filter(({ parent }) => !parent);

  return [...parentItems];
};

export default prepareItems;
