/* global Worker */
import React, {
  useCallback, useState, useMemo, useEffect, useRef,
} from 'react';
import {
  arrayOf, bool, number, object, oneOfType, string, shape, func, instanceOf,
} from 'prop-types';
import I18n from 'i18n-js';
import {
  Button, Input, Spin, Tree,
} from 'antd';
import { debounce } from 'lodash-es';
import { nanoid } from 'nanoid';
import airbrakeClient from 'configuredAirbrakeClient';
import FaIcon from 'components/fa-icon';
import Item from './nested/Item';
import modifyFilterListStyles from './helpers/modifyFilterListStyles';
import setSearchInputFocus from './helpers/setSearchInputFocus';
import prepareSearchApi from './helpers/prepareSearchApi';

const filterTreeWorkersQueue = new Map();
let currentFilterTreeWorkerId = null;

const TopMenuFilter = ({
  applyUrl,
  checkedKeys,
  items,
  onCheck,
  onSelect,
  plainItemsIndex,
  selectedKeys,
  title,
  visible,
  expandAll,
  expandKeys,
}) => {
  const debounceTimeout = plainItemsIndex.size > 10000 ? 500 : 100;
  const filterWrapperRef = useRef(null);

  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [filteredItems, setFilteredItems] = useState(items);

  const searchApi = useMemo(() => prepareSearchApi(plainItemsIndex), [plainItemsIndex]);

  const terminateFilterTreeWorker = (workerId) => {
    if (!workerId) { return; }

    const worker = filterTreeWorkersQueue.get(workerId);
    if (!worker) { return; }

    worker.terminate();
    filterTreeWorkersQueue.delete(workerId);
  };

  const handleRebuildTreeWorkerMessage = ({ data }) => {
    const { newItems, workerId } = data;

    if (workerId !== currentFilterTreeWorkerId) { return; }

    setFilteredItems(newItems);
    setSearching(false);
    terminateFilterTreeWorker(workerId);
  };

  const defineFilteredItems = async (filter) => {
    terminateFilterTreeWorker(currentFilterTreeWorkerId);
    const foundItemKeys = await searchApi.search(filter);

    const worker = new Worker(new URL('helpers/filterTreeWorker.js', import.meta.url));
    worker.addEventListener('message', handleRebuildTreeWorkerMessage, false);

    currentFilterTreeWorkerId = nanoid();
    filterTreeWorkersQueue.set(currentFilterTreeWorkerId, worker);

    worker.postMessage({
      items,
      plainItemsIndex,
      foundItemKeys,
      currentFilterTreeWorkerId,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFilteredItems = useCallback(debounce(async (filter) => {
    try {
      setSearching(true);
      defineFilteredItems(filter);
    } catch (error) {
      airbrakeClient.notify({ error });
    }
  }, debounceTimeout), []);

  const onChangeSearchValue = useCallback(({ target }) => {
    const filter = target.value;
    setSearchValue(filter);

    if (!filter) { setFilteredItems(items); return; }

    updateFilteredItems(filter);
  }, [updateFilteredItems, items]);

  useEffect(() => {
    if (!visible) { return; }

    modifyFilterListStyles(filterWrapperRef.current);
    setSearchInputFocus(filterWrapperRef.current);
  }, [visible]);

  const checkable = onCheck instanceof Function;
  const selectable = onSelect instanceof Function;

  const searchIcon = (searching
    ? <Spin size="small" />
    : <FaIcon modifier="fal" icon="search" />);

  return (
    <div className="top-menu-filter" ref={filterWrapperRef}>
      <div className="top-menu-filter__header">
        { title }
      </div>
      <div className="top-menu-filter__search-container">
        <Input
          className="top-menu-filter__search-input"
          onChange={onChangeSearchValue}
          placeholder={I18n.t('search')}
          value={searchValue}
          suffix={searchIcon}
        />
      </div>

      <Tree
        checkable={checkable}
        checkedKeys={checkedKeys}
        checkStrictly
        defaultExpandAll={expandAll}
        defaultExpandedKeys={expandKeys}
        height={400}
        onCheck={onCheck}
        onSelect={onSelect}
        rootClassName="top-menu-filter__list"
        selectable={selectable}
        selectedKeys={selectedKeys}
        // @ts-ignore
        titleRender={Item}
        treeData={filteredItems}
      />

      {
        applyUrl && (
          <div className="top-menu-filter__apply">
            <Button
              disabled={searching}
              href={applyUrl}
              type="primary"
            >
              {I18n.t('apply')}
            </Button>
          </div>
        )
      }
    </div>
  );
};

TopMenuFilter.propTypes = {
  applyUrl: string,
  checkedKeys: shape({
    checked: arrayOf(string),
    halfChecked: arrayOf(string),
  }),
  items: arrayOf(shape({
    active: bool,
    ancestorIds: arrayOf(number),
    // eslint-disable-next-line react/forbid-prop-types
    children: arrayOf(object),
    depth: number,
    hasSubfolders: bool,
    id: oneOfType([number, string]),
    key: string,
    title: string,
    type: string,
  })),
  onCheck: func,
  onSelect: func,
  plainItemsIndex: instanceOf(Map),
  title: string,
  selectedKeys: arrayOf(string),
  visible: bool.isRequired,
  expandAll: bool,
  expandKeys: arrayOf(string),
};

TopMenuFilter.defaultProps = {
  applyUrl: null,
  checkedKeys: null,
  items: [],
  onCheck: null,
  onSelect: null,
  selectedKeys: [],
  plainItemsIndex: new Map(),
  title: '',
  expandAll: true,
  expandKeys: [],
};

export default TopMenuFilter;
