/* eslint-disable react/prop-types, react/jsx-props-no-spreading */

import React from 'react';
import { ConfigProvider } from 'antd'; // eslint-disable-line no-restricted-imports
import antdLocale from 'locales/antdLocale';

const CWOConfigProvider = ({ children, ...props }) => (
  <ConfigProvider
    locale={antdLocale()}
    {...props}
  >
    {children}
  </ConfigProvider>
);

export default CWOConfigProvider;
